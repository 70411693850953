<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <h1 class="item_title mt-3 mb-6 ml-0">{{data.name}}</h1>

      <v-text-field
        label="Введите число.."
        v-model="numVal"
        filled
        single-line
        counter="10"
        type="number"
        hide-details="auto"
        @change="saveData"
        :rules="rules"
        :error="error"
      ></v-text-field>

    </v-col>

  </v-row>
</template>

<script>
    export default {
        name: 'TextField',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.numVal = null
            }
        },
        data () {
            return {
                rules: [
                    value => (value || !this.item.conf.fill) ? true : 'поле обязательноe для заполнения'
                ],
                data: this.item,
                numVal: null
            }
        },
        methods: {
            saveData: function () {
                this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.numVal, type: 'numfield' })
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => {
                    return (item.id === this.data.id)
                })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            },
            error: function() {
                return !(this.content || !this.item.conf.fill)
            }
        },
        mounted() {
            this.numVal = this.val
        }
    }
</script>

<style scoped>

</style>
