import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify)

Vue.component('App', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'ru'
    }
  }
})

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  theme: {
    themes: {
      light: {
        orange: '#F29D3F',
        lightGray: '#ddd'
      }
    }
  }
})
