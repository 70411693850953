<template>
    <v-row v-if="list">
      <v-col cols="12">
          <v-row>
            <v-col class="text-center mt-6 mb-4 mr-0 ml-0">
              <img :src="(image) ? (link + '/storage/app/banners/' + image) : images.banner" class="imageHeader m-0">
            </v-col>
          </v-row>

          <draggable
            v-model="list.items"
            handle=".moveItem"
            @end="moveItem">
            <!--<v-slide-y-transition :group="true">-->
            <div v-for="(item, i) in list.items" :key="'item'+i" class="border-hover mb-3">

              <div class="addItemAbove">
                <div class="addItem">
                  <v-menu
                    :key="'menu'+i"
                    :nudge-width="200"
                    offset-x
                    right
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon color="white" class="pointer">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="addItem(i, aItem.slug)" v-for="(aItem, aIndex) in aItems" :key="'aItem'+aIndex">
                        <v-icon class="mr-2" :color="aItem.color">{{aItem.icon}}</v-icon>
                        {{aItem.name}}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div class="moveLeft">
                <div class="moveItem">
                  <v-btn icon>
                    <v-icon color="white" class="pointer">mdi-cursor-move</v-icon>
                  </v-btn>
                </div>
              </div>

              <div class="optionsRight">
                <div class="optionsItem">
                  <v-menu
                    :key="'menu'+i"
                    :nudge-width="200"
                    offset-x
                    right
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon color="white" class="pointer">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="cofigureItem(i, 'textfield')" disabled>
                        <v-icon class="mr-2">mdi-cog-sync-outline</v-icon>
                        Изменить настройки
                      </v-list-item>
                      <v-list-item @click="delItem(i)">
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                        Удалить элемент
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <RegionField :number="i" :item="item" v-if="item.type=='regionfield'" />
              <EccField :number="i" :item="item" v-if="item.type=='eccfield'" />
              <TextField :number="i" :item="item" v-if="item.type=='textfield'" />
              <NumField :number="i" :item="item" v-if="item.type=='numfield'" />
              <TextArea :number="i" :item="item" v-if="item.type=='textarea'" />
              <SelectField :number="i" :item="item" v-if="item.type=='selectfield'" />
              <CheckList :number="i" :item="item" v-if="item.type=='checklist'" />
              <RadioList :number="i" :item="item" v-if="item.type=='radiolist'" />
              <DatePicker :number="i" :item="item" v-if="item.type=='datepicker'" />
              <HeaderArea :number="i" :item="item" v-if="item.type=='headerarea'" />
              <DescriptionArea :number="i" :item="item" v-if="item.type=='descriptionarea'" />
              <Document :number="i" :item="item" v-if="item.type=='document'" />
              <Video :number="i" :item="item" v-if="item.type=='video'" />
              <Rating :number="i" :item="item" v-if="item.type=='rating'" />

              <div class="addItemBelow" v-if="i==(list.items.length-1)">
                <div class="addItem">
                  <v-menu
                    :key="'menu'+i"
                    :nudge-width="200"
                    offset-x
                    right
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs">
                        <v-icon color="white" class="pointer">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="addItem(i+1, aItem.slug)" v-for="(aItem, aIndex) in aItems" :key="'aItem'+aIndex">
                        <v-icon class="mr-2" :color="aItem.color">{{aItem.icon}}</v-icon>
                        {{aItem.name}}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </div>
            <!--</v-slide-y-transition>-->
          </draggable>
      </v-col>
    </v-row>
</template>

<script>

    import TextField from './TextField'
    import NumField from './NumField'
    import TextArea from './TextArea'
    import SelectField from './SelectField'
    import CheckList from './CheckList'
    import RadioList from './RadioList'
    import DatePicker from './DatePicker'
    import HeaderArea from './HeaderArea'
    import RegionField from './RegionField'
    import EccField from './EccField'
    import DescriptionArea from './DescriptionArea'
    import Document from './Document'
    import Video from './Video'
    import Rating from './Rating'
    import draggable from 'vuedraggable'

    export default {
        name: 'Page',
        props: ['number', 'page'],
        watch: {
            page: function (item) {
                this.list = item
            }
        },
        components: {
            TextField,
            NumField,
            TextArea,
            SelectField,
            CheckList,
            RadioList,
            DatePicker,
            HeaderArea,
            RegionField,
            EccField,
            DescriptionArea,
            Document,
            Video,
            Rating,
            draggable
        },
        data() {
            return {
                images: {
                    banner: require('./../../assets/img/banner3.png')
                },
                list: this.page
            }
        },
        methods: {
            addItem: function (i, type) {
                const item = {
                    number: i,
                    content:
                        {
                            name: '',
                            type: type,
                            conf: {}
                        }
                }

                this.$store.dispatch('addItem', item)
            },
            delItem: function (i) {
                this.$store.dispatch('deleteItem', i)
            },
            configureItem: function (i, type) {

            },
            moveItem: function (event) {
                this.$store.dispatch('changeOrder', this.list.items)
            }
        },
        computed: {
          aItems: function () {
              return this.$store.getters.anketItems
          },
          image: function () {
              return this.$store.getters.currentAnket.image
          },
          link: function () {
              return this.$store.getters.link
          }
        }
    }
</script>

<style>
  .home {
    height: 100%;
  }

  .border-hover:hover {
    outline: 2px solid #F29D3F;
  }

  .addItem {
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: .5s;
    cursor: pointer;
    display: none;
    border-radius: 36px;
    height: 36px;
    width: 36px;
    margin-left: 50%;
    margin-top: 12px;
    background-color: #d68839;
  }

  .addItemAbove {
    position: absolute;
    width: 100%;
    margin-top: -32px;
    margin-left: -32px;
    height: 64px;
  }

  .addItemAbove:hover > .addItem {
    display: block;
  }

  .addItemBelow {
    position: absolute;
    width: 100%;
    bottom: -24px;
    margin-left: -32px;
    height: 64px;
  }

  .addItemBelow:hover > .addItem {
    display: block;
  }

  .optionsRight {
    position: absolute;
    width: 48px;
    right: -8px;
    display: none;
  }

  .optionsItem {
    cursor: pointer;
    border-radius: 36px;
    height: 36px;
    width: 36px;
    margin-left: 50%;
    margin-top: 12px;
    background-color: #d68839;
  }

  .moveLeft {
    position: absolute;
    width: 48px;
    left: -42px;
    display: none;
  }

  .moveItem {
    cursor: move !important;
    border-radius: 36px;
    height: 36px;
    width: 36px;
    margin-left: 50%;
    margin-top: 12px;
    background-color: #ccc;
  }

  .border-hover:hover > .optionsRight, .border-hover:hover > .moveLeft {
    display: block;
  }

  .imageHeader {
    max-width: 700px !important
  }

  @media (max-width: 800px) {
    .imageHeader {
      max-width: 500px !important
    }
  }

  @media (max-width: 600px) {
    .imageHeader {
      max-width: 360px !important
    }
  }

  @media (max-width: 400px) {
    .imageHeader {
      max-width: 300px !important
    }
  }
</style>
