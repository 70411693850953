var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.anketa)?_c('v-row',{staticClass:"mt-0"},[(_vm.loading)?_c('v-col',{staticClass:"text-center pt-5"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e(),(!_vm.loading)?_c('v-col',{staticClass:"ml-4 mr-4"},[_c('v-card',{staticClass:"mt-16 mt-lg-6 ma-auto pr-8",attrs:{"flat":"","height":"90%","max-width":"1200"}},[_c('v-tabs',{attrs:{"dark":"","centered":"","background-color":"#3F86C2","show-arrows":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"#3F86C2"}}),_c('v-tab',[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" clipboard-check-multiple-outline ")]),_vm._v(" Перечень анкет ")],1),_vm._l((_vm.filteredList),function(page,c){return _vm._l((page),function(question,i){return _c('v-tab',{key:'t'+c+'i'+i,on:{"change":function($event){return _vm.getSvod(question.id)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.getIcon(question.type))+" ")]),_vm._v(" Вопрос "+_vm._s((c+1))+"."+_vm._s((i+1))+" ")],1)})})],2),_c('v-tabs-items',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('sign-list'),_vm._l((_vm.filteredList),function(page,c){return _vm._l((page),function(question,j){return _c('v-tab-item',{key:'tt'+c+'ii'+j},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-center lighten-4 grey pr-16"},[_vm._v(" "+_vm._s(question.name)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"float-right",staticStyle:{"position":"absolute","right":"8px","top":"6px"},attrs:{"icon":"","tile":"","large":"","elevation":"2"},on:{"click":function($event){return _vm.downloadData(question.id, question.type)}}},on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("загрузить результаты в формате Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.regionCheck)?_c('v-btn',_vm._g({staticClass:"float-right",staticStyle:{"position":"absolute","right":"62px","top":"6px"},attrs:{"icon":"","tile":"","large":"","elevation":"2"},on:{"click":function($event){return _vm.downloadRegionData(question.id, question.type)}}},on),[_c('v-icon',[_vm._v("mdi-store-search")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("загрузить результаты по муниципалитетам в формате Excel")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.regionCheck)?_c('v-btn',_vm._g({staticClass:"float-right",staticStyle:{"position":"absolute","right":"116px","top":"6px"},attrs:{"icon":"","tile":"","large":"","elevation":"2"},on:{"click":function($event){return _vm.downloadRegionCaseData(question.id, question.type)}}},on),[_c('v-icon',[_vm._v("mdi-human-male-female-child")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("загрузить результаты по муниципалитетам по возрастным группам в формате Excel")])])],1),_c('v-card-text',[(_vm.loadingData)?_c('div',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.toHeader(question.type),"items":(_vm.result) ? _vm.toTable(_vm.result, question.type): [],"items-per-page":_vm.itemsPerPage},on:{"update:items-per-page":_vm.ipp},scopedSlots:_vm._u([{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v("скачать >>")])]}}],null,true)})],1):_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}})],1)])],1)],1)})})],2)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }