<template>
<v-row class="mt-3 mb-0 pb-0" v-if="anketa">
  <v-col v-if="loading" class="text-center pt-5">

    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-col>

  <v-tooltip right v-if="uniqueId">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="listButton mt-4 ml-8 v-btn--fixed"
        style="left: 80px"
        fab
        large
        v-bind="attrs"
        v-on="on"
        color="#eee"
        @click="newSign"
      >
        <v-icon dark>
          mdi-text-box-plus-outline
        </v-icon>
      </v-btn>
    </template>
    <span>Заполнить новую анкету</span>
  </v-tooltip>

  <v-tooltip bottom v-if="uniqueId" open-on-click
             :open-on-focus="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="listButton mt-4 ml-8 v-btn--fixed"
        fab
        large
        v-bind="attrs"
        v-on="on"
        color="#eee"
        @click="copyLink"
      >
        <v-icon dark>
          mdi-link-variant
        </v-icon>
      </v-btn>
    </template>
    <span>{{copyMessage}}</span>
  </v-tooltip>

  <v-col v-if="(!loading)">
    <div v-if=(!anketa.closed)>
    <v-carousel
      class="mb-8"
      v-model="pageNum"
      :show-arrows="false"
      hide-delimiter-background
      hide-delimiters
      height="100%"
    >
      <v-carousel-item
        v-for="(page, i) in anketa.pages"
        :key="i"
      >
        <div style="max-width: 800px" class="ma-auto pr-3 pl-2 pr-md-0 pl-md-0">

          <v-card max-width="800" height="100%" class="mt-6" v-if="(page)">

            <Page :number="i" :page="page"></Page>
            <div class="mb-3 text-right mt-8 pb-8">
              <v-btn color="primary ml-8 pl-8 pr-8 float-left" large v-if="pageNum>0"
                     @click="previousPage">НАЗАД</v-btn>
              <v-btn color="primary mr-8 pl-8 pr-8" large v-if="(pageNum+1)<anketa.pages.length"
                     @click="nextPage">ДАЛЕЕ</v-btn>

              <v-dialog
                v-if="(pageNum+1)===anketa.pages.length"
                v-model="saveDialog"
                width="500"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success mr-8 pl-8 pr-8"
                         large
                         v-bind="attrs"
                         v-on="on"
                         @click="saveSign">СОХРАНИТЬ</v-btn>
                </template>
                <v-card v-if="!dialogStatus">
                  <v-card-title><div class="text-center  mt-3 ma-auto">Анкетирование завершено</div><div class="text-center ma-auto color-blue">Ваш ответ сохранён</div>
                  <v-img :src="images.done" class="mt-2" />
                  </v-card-title>
                  <v-card-text class="pb-2">
                    <!-- <div class="pb-2">Вы по-прежнему имеете возможность редактировать ответ <br><strong>в текущем браузере</strong>.</div> -->
                    <v-card-text class="text-center mt-0 pb-0">
                      <v-btn
                        color="green"
                        class="ma-auto mr-0 mb-3"
                        dark
                        @click="newSign"
                      >
                        Новый ответ
                      </v-btn><br>
                      <div
                        color="blue"
                        class="answer_button ml-0"
                        dark
                        text
                        @click="saveDialog = false"
                      >
                        Оставить текущий ответ для редактирования
                    </div>
                    </v-card-text>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text class="mt-4">
                  <!-- <div class="pb-2">Если вы хотите добавить другой ответ на этом компьютере, нажмите на кнопку "Новый ответ"</div> -->
                  <div class="pb-2 text-message-description">
                    <v-tooltip
                      v-model="showLinkMessage"
                      open-on-click
                      :open-on-focus="false"
                      :open-on-hover="false"
                      right
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn class="float-right mb-4 ml-4 pt-7 pb-7 copy_button"
                               v-on="on"
                               @click="copyLink">Скопировать<br>ссылку</v-btn>
                      </template>
                      <span>Ссылка скопирована в буфер обмена</span>
                    </v-tooltip>
                    Если вы хотите иметь возможность редактировать текущий ваш ответ на другом компьютере или браузере - можете скопировать себе прямую ссылку.
                  </div>
                  </v-card-text>
                </v-card>
                <v-card v-else-if="dialogStatus === 'fulfil'">
                  <v-card-title><div class="text-center ma-auto">Есть проблема</div></v-card-title>
                  <v-card-text>
                    У вас не заполнены следующие поля:
                    <ul class="mt-3">
                      <li v-for="(item, index) in blankList" :key="'bl'+index" class="mt-2 font-weight-bold">
                        {{item}}
                      </li>
                    </ul>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text class="text-center mt-4">
                    <v-btn
                      color="blue"
                      class="ma-auto"
                      dark
                      @click="saveDialog = false"
                    >
                      Закрыть окно
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>

            </div>
          </v-card>
        </div>
      </v-carousel-item>
    </v-carousel>
    </div>
    <div v-else>
      <v-card width="800" class="ma-auto">
      <v-card-title>
        <div class="text-center mt-0 ma-auto">
          <v-img :src="images.closed" width="400" class="mt-0 mx-auto" />
          Анкета не доступна для заполнения
        </div>
      </v-card-title>
      <v-card-text class="text-center mt-0 pb-6">
          <b>Закончились сроки анкетирования</b><br/>
          или <br/>
          <b>Анкета находится на подготовительном этапе</b><br/>
      </v-card-text>
      </v-card>
    </div>
  </v-col>
</v-row>
</template>

<script>
    import Page from '../components/Form/Page'
    export default {
        name: 'Form',
        props: ['slug'],
        components: {
            Page
        },
        data() {
            return {
                loading: true,
                pageNum: 0,
                saveStatus: false,
                saveDialog: false,
                dialogStatus: null,
                showLinkMessage: false,
                showLinkMessage2: false,
                blankList: [],
                copyMessage: 'Сохранить ссылку на ваш ответ',
                images: {
                  done: require('./../assets/img/done.png'),
                  closed: require('./../assets/img/closed.png')
                }
            }
        },
        created() {
            this.$store.dispatch('getAnketBySlug', { slug: this.$route.params.slug, id: (this.$route.params.id) ? this.$route.params.id : null }).then((data) => {
                    this.loading = false
            })
            this.$store.dispatch('getRegions')
            this.$store.dispatch('getEcc')
        },
        computed: {
            anketa: function () {
                return this.$store.getters.currentSignAnket
            },
            uniqueId: function () {
                return this.$store.getters.currentSignUnique
            },
            sign: function () {
                return this.$store.getters.currentSign
            }
        },
        methods: {
            nextPage: function () {
              this.pageNum++
              window.scrollTo({
                  top: 0
              })
            },
            previousPage: function () {
              this.pageNum--
              window.scrollTo({
                  top: 0
              })
            },
            saveSign: function () {
                this.blankList = []
                var list = []
                this.anketa.pages.forEach((page, index) => {
                    list[index] = page.items.filter((item) => {
                        return (item.conf.fill === true)
                     })
                    list[index].forEach((listItem, listIndex) => {
                      const a = this.sign.filter((signItem) => {
                          return (signItem.id === listItem.id)
                      })
                      if ((!a.length) || (!a[0].value)) {
                          this.blankList.push(list[index][listIndex].name)
                      }
                    })
                })
                if (!this.blankList.length) {
                    this.dialogStatus = null
                    this.$store.dispatch('saveSign').then(data => {
                        this.saveStatus = true
                    })
                } else {
                    this.dialogStatus = 'fulfil'
                    this.saveStatus = true
                }
            },
            newSign: function () {
                this.$store.dispatch('newSign')
                this.$router.push({ name: 'Form' })
                this.saveDialog = false
            },
            copyLink: function() {
                window.navigator.clipboard.writeText('https://form.kkr.ru' + this.$route.path + '/' + this.uniqueId)
            }
        }

    }
</script>

<style scoped>

  .leftButton {
    position: absolute;
    margin-left: -60px;
    margin-top: 24px;
    min-width: 40px !important;
  }

  .rightButton {
    position: absolute;
    margin-left: 816px;
    margin-top: 24px;
    min-width: 40px !important;
  }

  .listButton:hover {
    color: #407ad8;
    cursor: pointer;
  }
  .text-message-description {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }

  .answer_button {
    font-size: 12px !important;
    text-decoration: underline;
    text-transform: inherit;
    cursor: pointer;
  }

  .answer_button:hover {
    color: #407ad8;
  }

  .copy_button {
    font-size: 12px !important;
    color: #666;
  }

  .cross {
    position: absolute;
    right: 16px;
  }

  .color-blue {
    color: #407ad8;
  }

</style>
