<template>

    <v-row class="mt-0" v-if="anketa">
      <v-col v-if="loading" class="text-center pt-5">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col v-if="!loading" class="ml-4 mr-4">

        <v-card flat height="90%" class="mt-16 mt-lg-6 ma-auto pr-8" max-width="1200">

          <v-tabs
            v-model="step"
            dark
            centered
            background-color="#3F86C2"
            show-arrows
          >
            <v-tabs-slider color="#3F86C2"></v-tabs-slider>
            <v-tab> <v-icon class="mr-1">
              clipboard-check-multiple-outline
            </v-icon>
              Перечень анкет
            </v-tab>

            <template v-for="(page, c) in filteredList">
              <v-tab
                v-for="(question, i) in page"
                :key="'t'+c+'i'+i"
                @change="getSvod(question.id)"
              > <v-icon class="mr-1">
                {{getIcon(question.type)}}
              </v-icon>
                Вопрос {{ (c+1) }}.{{ (i+1) }}
              </v-tab>
            </template>
          </v-tabs>

          <v-tabs-items v-model="step">
<!------------------------------------ Список ----------------------------------------->
            <sign-list />
<!--------------------------------- Выборки ------------------------------------------->
            <template v-for="(page, c) in filteredList">
              <v-tab-item
                v-for="(question, j) in page"
                :key="'tt'+c+'ii'+j"
              >
                <v-card flat>
                  <v-card-title class="text-center lighten-4 grey pr-16">
                    {{ question.name }}
                    <v-tooltip right>
                      <template v-slot:activator="{ on}">
                    <v-btn icon v-on="on" tile large elevation="2" class="float-right" style="position: absolute; right:8px; top: 6px" @click="downloadData(question.id, question.type)">
                      <v-icon>mdi-microsoft-excel</v-icon>
                    </v-btn>
                      </template>
                      <span>загрузить результаты в формате Excel</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on}">
                    <v-btn icon v-on="on" tile large elevation="2" class="float-right" style="position: absolute; right:62px; top: 6px" @click="downloadRegionData(question.id, question.type)" v-if="regionCheck">
                      <v-icon>mdi-store-search</v-icon>
                    </v-btn>
                      </template>
                      <span>загрузить результаты по муниципалитетам в формате Excel</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on}">
                    <v-btn icon v-on="on" tile large elevation="2" class="float-right" style="position: absolute; right:116px; top: 6px" @click="downloadRegionCaseData(question.id, question.type)" v-if="regionCheck">
                      <v-icon>mdi-human-male-female-child</v-icon>
                    </v-btn>
                      </template>
                      <span>загрузить результаты по муниципалитетам по возрастным группам в формате Excel</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="loadingData">
                    <v-data-table
                      @update:items-per-page="ipp"
                      :headers="toHeader(question.type)"
                      :items="(result) ? toTable(result, question.type): []"
                      :items-per-page="itemsPerPage"
                      class="elevation-3"
                    >
                    <template v-slot:item.link="{ item }">
                        <a :href="item.link" target="_blank">скачать >></a>
                    </template>
                    </v-data-table>
                    </div>
                    <div v-else>
                        <v-progress-linear
                        indeterminate
                        color="cyan"
                        ></v-progress-linear>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-card>

      </v-col>
    </v-row>
</template>
<script>

  import SignList from '../components/Result/SignBigList'

    export default {
        name: 'Result',
        components: {
            'sign-list': SignList
        },
        data() {
            return {
                loading: true,
                loadingData: true,
                step: 0,
                headers: [{
                    text: 'Номер',
                    align: 'middle',
                    sortable: true,
                    value: 'number',
                    width: '15%'
                }, {
                    text: 'Ответ',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '85%'
                }
                ],
                groupHeaders: [{
                    text: 'Номер',
                    align: 'middle',
                    sortable: true,
                    value: 'number',
                    width: '15%'
                }, {
                    text: 'Ответ',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: '60%'
                }, {
                    text: 'Количество',
                    align: 'middle',
                    sortable: true,
                    value: 'count',
                    width: '25%'
                }
                ],
                docHeaders: [{
                    text: 'Номер',
                    align: 'middle',
                    sortable: true,
                    value: 'number',
                    width: '15%'
                }, {
                    text: 'Документ',
                    align: 'start',
                    sortable: true,
                    value: 'link',
                    width: '60%'
                }
                ],
                regionCheck: false
            }
        },
        mounted() {
                this.$store.dispatch('getAnketResultBySlug', this.$route.params.slug).then((data) => {
                  this.$store.dispatch('getBigSignList', { id: (this.anketa.id) ? this.anketa.id : this.$route.params.id, type: 'list' }).then(() => {
                      this.loading = false
                  })
              })
        },
        computed: {
            anketa: function () {
                return this.$store.getters.currentAnket
            },
            result: function () {
                return this.$store.getters.allResults.svod
            },
            aItems: function () {
                return this.$store.getters.anketItems
            },
            filteredList: function() {
                var pge = []
                this.anketa.pages.forEach((page, p) => {
                    pge[p] = this.anketa.pages[p].items.filter((item, i) => {
                        if (item.type === 'regionfield') this.regionCheck = true
                        return ((item.type !== 'headerarea') && (item.type !== 'descriptionarea'))
                    })
                })
                return pge
            },
            itemsPerPage: function() {
                return (parseInt(this.$store.getters.currentItemsPerPage)) ? parseInt(this.$store.getters.currentItemsPerPage) : 10
            },
            link: function() {
                return this.$store.getters.link
            }
        },
        methods: {
            getSvod: function(id) {
                this.loadingData = false
                this.$store.dispatch('getBigSignSvod', { id: id, type: 'svod' }).then(() => {
                      this.loadingData = true
                })
            },
            getIcon: function (type) {
                if (this.aItems) {
                    return this.aItems.find(item => {
                        return (item.slug === type)
                    }).icon
                }
            },
            toAnket: function () {
                this.$router.push('/')
            },
            toTable: function(data, type) {
                switch (type) {
                    case 'selectfield':
                    case 'regionfield':
                    case 'eccfield':
                    case 'radiolist': return this.groupToTable(data)
                    case 'checklist': return this.groupMultiToTable(data)
                    case 'rating': return this.groupRatingToTable(data)
                    case 'document': return this.allToDocTable(data)
                    default: return this.allToTable(data)
                }
            },
            toHeader: function(type) {
                switch (type) {
                    case 'selectfield':
                    case 'checklist':
                    case 'regionfield':
                    case 'rating':
                    case 'eccfield':
                    case 'radiolist': return this.groupHeaders
                    case 'document': return this.docHeaders
                    default: return this.headers
                }
            },
            allToTable: function(data) {
                var answers = []
                data.forEach((item, i) => {
                    answers.push({ number: i + 1, name: item.value })
                })
                return answers
            },
            groupToTable: function(data) {
                var answers = []
                data.forEach((item, i) => {
                    answers.push({ number: i + 1, name: item.value, count: item.count })
                })
                return answers
            },
            allToDocTable: function(data) {
              var answers = []
                data.forEach((item, i) => {
                    answers.push({ number: i + 1, link: this.link + '/storage/app/' + item.value })
                })
                return answers
            },
            groupMultiToTable: function(data) {
                var answers = []
                data.forEach((item, i) => {
                    answers.push({ number: i + 1, name: item.value, count: item.count })
                })
                return answers
            },
            groupRatingToTable: function(data) {
                var answers = []
                data.forEach((item, i) => {
                    answers.push({ number: i + 1, name: item.value, count: item.count })
                })
                return answers
            },
            downloadData: function (id, type) {
                window.open(this.link + '/forms/excel/result/svod?id=' + id + '&type=' + type, '_blank')
            },
            downloadRegionData: function (id, type) {
                window.open(this.link + '/forms/excel/result/svod/region?id=' + id + '&type=' + type, '_blank')
            },
            downloadRegionCaseData: function (id, type) {
                window.open(this.link + '/forms/excel/result/svod/age?id=' + id + '&type=' + type, '_blank')
            },
            ipp: function(num) {
                this.$store.dispatch('setItemsPerPage', num)
            }
        }
    }
</script>

<style scoped>

  .listButton:hover {
    color: #407ad8;
    cursor: pointer;
  }

::deep(.v-stepper__label) {
    text-align: center;
  }

::deep(.v-card__title) {
    padding-left: 24px;
    padding-right: 24px;
    display: block;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    word-break: normal !important;
  }

::deep(.v-card__text) {
    padding-left: 2px;
    padding-right: 2px;
  }

::deep(.text-start, .text-middle) {
    font-size: 16px !important;
  }
::deep(.text-middle) {
    text-align: center !important;
  }
</style>
