<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">
      <h1 class="ml-4 mr-4 mb-4 item_header" v-html="data.name"></h1>
    </v-col>
  </v-row>
</template>

<script>
    export default {
        name: 'HeaderArea',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            }
        }
    }
</script>

<style scoped>

</style>
