<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <v-textarea
        label="Введите заголовок"
        v-model="data.name"
        single-line
        rows="1"
        auto-grow
        loading="false"
        class="item_title"
        @blur="saveTitle"
      ><template v-slot:append>
        <v-tooltip left color="alert">
          <template v-slot:activator="{ on, attrs }">
            <v-icon :color="(check_fill)?'orange':'lightGray'"
                    class="check_fill"
                    @click="changeFill"
                    v-bind="attrs"
                    v-on="on">mdi-check-underline-circle</v-icon>
          </template>
          <span v-if="check_fill">обязательно для заполнения</span>
          <span v-else>не обязательно для заполнения</span>
        </v-tooltip>
      </template>
      </v-textarea>

      <v-combobox
        label="Напишите часть названия"
        clearable
        filled
        single-line
        :rules="rules"
        :items="items"
      ></v-combobox>

    </v-col>
  </v-row>
</template>

<script>
    import HomeItems from '../Mixins/HomeItems.js'
    export default {
        name: 'EccField',
        mixins: [HomeItems],
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                date: null,
                menu: false
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            }
        },
        mounted() {
            if (!this.data.name) this.data.name = 'Выберите учреждение'
        },
        computed: {
            items: function () {
                return this.$store.getters.allEcc.map((item) => {
                    return item.name
                })
            }
        }
    }
</script>

<style scoped>

</style>
