<template>
  <v-dialog
    v-model="open"
    width="800"
    content-class="main_window pa-0 pt-10 pb-10 pb-18 pr-3 pl-12"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="mr-4 auth_link"
        v-bind="attrs"
        v-on="on"
        >
        <a style="cursor: pointer">авторизуйтесь</a>
      </div>
    </template>
          <v-row class="auth_box p-0 m-0">
            <v-col cols="12" class="m-0">
              <v-row>
                <v-col cols="5">
                  <div class="hr"></div>
                </v-col>
                <div style="width: 60px" class="ml-2">
                  <Icon :icon="icons.profileMale" class="icon"/>
                </div>
                <v-col class="pr-5">
                  <div class="hr"></div>
                </v-col>
              </v-row>
              <v-row class="p-3">
                <v-col cols="12" md="6">
                  <div class="or d-none d-md-block">или</div>
                  <v-row>
                    <v-col class="sign_box mr-4">
                      <v-row>
                        <v-col cols="12" class="title mt-4 mb-0 text-center">
                          Уже участник коворкинга!
                        </v-col>
                      </v-row>
                      <v-row class="mr-1">
                        <v-col cols="12" class="subtitle mt-0">
                          Вход в личный кабинет
                        </v-col>
                      </v-row>
                      <v-form @submit.prevent="onSubmit">
                        <v-row class="mt-2">
                          <v-col>
                            <v-text-field dense outlined hide-details v-model="form.email" name="email" placeholder="E-mail" class="auth_input ml-3 mr-3"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <v-text-field dense outlined hide-details type="password" v-model="form.password" name="password" placeholder="Пароль" class="auth_input ml-3 mr-3"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-1">
                          <v-col>
                            <v-btn color="#F29D3F" class="auth_button ml-3 mr-3" @click="login">Войти</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row class="mt-0 mr-1">
                        <!-- <v-col class="text-left pt-0 pl-4">
                          <v-checkbox
                            hide-details="true"
                            id="remember"
                            v-model="form.remember"
                            label="запомнить"
                            class="auth_remember pl-4">

                          </v-checkbox>
                        </v-col>
                        <v-col class="text-right pt-4 auth_forget mt-2">
                          <a :href="'password/reset/'">Забыли пароль?</a>
                        </v-col>-->
                      </v-row>
                      <v-row>
                        <v-col class="mt-2 pt-0 pb-0 mb-0 text-center">
                          <v-alert
                            dense
                            dark
                            color="red lighten-2"
                            v-if="errors">{{errors}}</v-alert>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="12" md="6" class="mt-3 mt-md-0">
                  <v-row>
                    <v-col class="sign_box mr-3">
                      <v-row>
                        <v-col cols="12" class="title mt-4 mb-0 text-center">
                          Ещё не участник?
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="subtitle mt-0">
                          Регистрация
                        </v-col>
                      </v-row>
                        <v-row class="mt-2">
                          <v-col>
                            <v-text-field outlined dense hide-details v-model="form.name" name="name" placeholder="Ф.И.О." class="auth_input ml-3 mr-3"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <v-text-field outlined dense hide-details v-model="form.reg_email" name="email" placeholder="E-mail" class="auth_input ml-3 mr-3"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <v-text-field outlined dense hide-details v-model="form.reg_password" type="password" name="password" placeholder="Пароль" class="auth_input ml-3 mr-3"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-1">
                          <v-col>
                            <v-btn class="auth_button ml-3" color="#F29D3F" disabled="true">Зарегистрироваться</v-btn>
                          </v-col>
                        </v-row>
                      <v-row class="mt-3 mb-0">
                        <v-col>
                          <v-alert
                            dense
                            dark
                            class="mb-0 "
                            color="blue text-center"
                            v-if="errors_reg">регистрация временно не доступна</v-alert>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
  </v-dialog>
</template>

<script>

    import profileMale from '@iconify/icons-et/profile-male'
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'Auth',
        props: ['show'],
        watch: {
            show: function(newVal) {
                this.open = newVal
            }
        },
        components: {
            Icon
        },
        data () {
            return {
                icons: {
                    profileMale
                },
                open: this.show,
                form: {
                    email: '',
                    password: '',
                    reg_email: '',
                    reg_password: '',
                    remember: false,
                    type: this.type,
                    id_task: this.idTask
                },
                errors: null,
                errors_reg: true
            }
        },
        methods: {
            login: function() {
                this.$store.dispatch('auth_login', { login: this.form.email, password: this.form.password }).then(response => {
                    if (response) {
                        this.open = false
                        this.$store.dispatch('synchronize')
                    }
                }).catch(error => {
                    this.errors = error
                    this.errors = 'Логин или пароль не подходят'
                })
            }
        }
    }
</script>

<style scoped>
  .auth_box {
    height: 460px;
  }

  /deep/ .main_window {
    background-color: #1D70B7;
    border-radius: 24px;
  }

  .auth_box .hr {
    border: 1px solid #FFFFFF;
    width: 100%;
    float: left;
    margin: 32px 8px;
  }

  .auth_box .icon {
    color: #ffffff;
    font-size: 61px;
    float: left;
  }

  .sign_box {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    height: 330px;
  }

  .title {
    font-family: Century Gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    margin: 16px 0px;
    color: #1D70B7;
  }

  .subtitle {
    font-family: Century Gothic, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;

    color: #000000;
  }

  .auth_input {
    background: #FFFFFF;
    border-radius: 16px;
  }

  .auth_link {
    float: right;
  }

  .auth_link:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .auth_remember {
    font-family: Century Gothic, sans-serif;
    padding: 8px 4px;
    font-size: 13px;
  }
  .auth_button {
    background: #F29D3F;
    border-radius: 16px;
    width: 94%;
    border: 0px;
    padding: 12px;
    font-family: Century Gothic, sans-serif;
    font-style: normal;
    font-size: 14px;
    text-transform: capitalize !important;
    line-height: 12px;
    text-align: center;

    color: #000000;
  }

  .auth_button:hover{
    background: #D67000;
    color: #fff;
  }

  .auth_forget {
    font-family: Century Gothic, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;

    color: #000000;
  }

  .or {
    position: absolute;
    margin-left: 324px;
    margin-top: 132px;
    font-family: Century Gothic, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    background: #FFFFFF;
    border: 2px solid #F29D3F;
    border-radius: 50px;
    padding: 12px 8px;
    width: 46px;
    text-align: center;
    color: #F29D3F;
    z-index: 99;
  }

  .error {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #e3342f;
  }

  /deep/ .v-input__slot {
    border: 0px !important;
  }
</style>
