<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">
      <v-text-field
        label="Введите embed ссылку с youtube"
        v-model="data.name"
        filled
        single-line
        hint=""
        counter="200"
        hide-details="auto"
      ></v-text-field>
      <div class="text-center">
        <iframe class="video-frame" :src="data.name" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </v-col>
  </v-row>
</template>

<script>
    export default {
        name: 'TextField',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                check_fill: this.item.conf.fill || false
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            },
             changeFill: function() {
                 this.check_fill = !this.check_fill
                 this.item.conf.fill = this.check_fill
                 this.$store.dispatch('refreshAnketItem', this.item)
             }
        }
    }
</script>

<style scoped>
  .check_fill {
    cursor: pointer;
  }

  .video-frame {
    width: 100%;
    height: 315px;
    max-width: 560px;
    max-height: 315px;
  }
</style>
