<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <v-textarea
        label="Введите заголовок"
        v-model="data.name"
        single-line
        rows="1"
        auto-grow
        loading="false"
        class="item_title"
        @blur="saveTitle"
      >
        <template v-slot:append>
          <v-tooltip left color="alert">
            <template v-slot:activator="{ on, attrs }">
              <v-icon :color="(check_fill)?'orange':'lightGray'"
                      class="check_fill"
                      @click="changeFill"
                      v-bind="attrs"
                      v-on="on">mdi-check-underline-circle</v-icon>
            </template>
            <span v-if="check_fill">обязательно для заполнения</span>
            <span v-else>не обязательно для заполнения</span>
          </v-tooltip>
        </template>
      </v-textarea>

      <v-select
        label="Выберите вариант"
        outlined
        @click="showMenu"
        no-data-text=""
      >
        <template v-slot:prepend-item>
          <v-list>
            <draggable  v-model="selItems"
                        handle=".moveSelItem">
              <v-list-item v-for="(sel, index) in selItems" :key="'sel'+index" class="selitem">
                <v-list-item-icon>
                    <v-icon color="grey lighten-1" class="moveSelItem">mdi-drag</v-icon>
                </v-list-item-icon>
                <v-text-field
                  label="Текст варианта.."
                  v-model="selItems[index]"
                  single-line
                  dense
                  loading="false"
                  class="selitem_title pb-0 pt-2 mb-0 mt-2"
                  @blur="saveVariant(index)"
                  :rules="rules"
                  :error="error"
                ></v-text-field>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1" @click="deleteVeriant(index)">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-list>
          <a @click="addVariant" class="link pl-5">+ добавить пункт</a>
        </template>

      </v-select>

    </v-col>

  </v-row>
</template>

<script>
    import HomeItems from '../Mixins/HomeItems.js'
    import draggable from 'vuedraggable'
    export default {
        name: 'SelectField',
        mixins: [HomeItems],
        props: ['item', 'number'],
        components: {
            draggable
        },
        watch: {
            item: function (item) {
                this.data = item
                this.selItems = (item.variants) ? this.item.variants : ['', '']
            }
        },
        data () {
            return {
                rules: [
                    value => (value || !this.item.conf.fill) ? true : 'поле обязательноe для заполнения'
                ],
                data: this.item,
                selItems: (this.item.variants) ? this.item.variants : ['', '']
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            },
            showMenu: function () {

            },
            addVariant: function () {
                this.selItems.push('')
            },
            deleteVeriant: function (index) {
                this.selItems.splice(index, 1)
            },
            saveVariant: function (index) {
                const value = { number: this.number, variants: this.selItems }
                this.$store.dispatch('setAnketVariants', value)
            }
        },
        computed: {
          error: function() {
            return !(this.content || !this.item.conf.fill)
          }
        }
    }
</script>

<style scoped>
  .selitem:hover {
    background-color: #eee;
  }

  .selitem_title {
    font-size: 16px;
    color: #333;
  }

  .link {
    font-size: 16px;
  }

  .link:hover {
    color: #295bb5;
  }

  .moveSelItem{
    cursor: move;
  }

</style>
