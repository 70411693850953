<template>
  <v-row class="mt-3">
    <v-col>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="listButton mt-0 ml-6 v-btn--fixed"
            fab
            large
            v-bind="attrs"
            v-on="on"
            color="#eee"
            @click="anketBack"
          >
            <v-icon dark>
              mdi-form-select
            </v-icon>
          </v-btn>
        </template>
        <span>Вернуться к текущей анкете</span>
      </v-tooltip>

      <!-- <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="listButton ml-6 v-btn--fixed"
            style="margin-top: 90px"
            fab
            large
            v-bind="attrs"
            v-on="on"
            color="#eee"
            @click="clearCache"
          >
            <v-icon dark>
              mdi-bucket-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Почистить локальные данные</span>
      </v-tooltip> -->

      <v-container>
        <v-row>
          <v-col class="text-center">
            <h1>Мои анкеты</h1>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="8">
            <v-card shaped width="200" height="160" class="float-left mr-5 mt-5 pb-3">
              <v-card-title><div class="ma-auto">Новая анкета</div></v-card-title>
              <v-card-text class="text-center">
                <v-btn
                  class="mt-0 ml-0"
                  fab
                  large
                  dark
                  color="#3F86C2"
                  @click="anketNew()"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
            <v-card  shaped width="200" height="200" v-for="(anket, i) in ankets" :key="'anket'+i" class="anketCard float-left mr-5 mt-5">
              <v-card-title class="imageCard">
                  <img
                    class="pt-0 pl-0 pr-0"
                    alt="user"
                    width="200"
                    :src="images.banner"
                  >
              </v-card-title>
              <v-card-subtitle class="subtitleCard text-center">
                <div class="titleCard blank ma-auto mt-6" v-if="anket.id <= 0">Черновик<br><span class="blank_description">анкета не сохранена</span></div>
                <div class="titleCard ma-auto mt-6" v-else>{{anket.name}}</div>
              </v-card-subtitle>
              <v-card-actions>
                <v-btn @click="anketGo(anket.id, i)" color="#D9EDF9" class="ml-auto mr-4" depressed>ПЕРЕЙТИ</v-btn>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="delButton"
                      v-bind="attrs"
                      v-on="on"
                      @click="delAnket(i)">
                      mdi-trash-can-outline
                    </v-icon>
                  </template>
                  <span>Удалить анкету</span>
                </v-tooltip>
                <confirm ref="conf"></confirm>
              </v-card-actions>
             </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
    import Confirm from '../components/Confirm'

    export default {
        name: 'List',
        data() {
            return {
                images: {
                    banner: require('./../assets/img/banner3.png')
                }
            }
        },
        mounted() {
            this.$store.dispatch('getAnkets')
        },
        components: {
            Confirm
        },
        computed: {
            ankets: function () {
                return this.$store.getters.allAnkets
            }
        },
        methods: {
          anketGo: function (id, num) {
            this.$store.dispatch('changeAnketId', { id: id, number: num }).then(() => {
              this.$router.push('/')
            })
          },
          anketBack: function () {
            this.$router.push('/')
          },
          anketNew: function (id) {
              this.$store.dispatch('newAnket').then(() => {
                  this.$router.push('/')
              })
          },
          clearCache: function () {
              this.$store.dispatch('clearCache')
          },
          delAnket: function (num) {
              this.$refs.conf[0].open('Предупреждение', 'Вы уверены, что хотите удалить анкету?', {
                  color: '#c96511',
                  width: 290,
                  zIndex: 200
              }).then(conf => {
                  if (conf) {
                      this.$store.dispatch('deleteAnket', num)
                  }
              })
          }
        }
    }
</script>

<style scoped>
  .titleCard {
    color: #333;
    font-size: 18px;
    line-height: 1.5rem;
    word-break: normal;
    height: 80px;
    font-weight: 400;
  }

  .anketCard:hover .delButton {
    opacity: 1;
  }

  .blank {
    color: #d85956;
  }

  .blank_description {
    font-size: 12px;
    color: #999;
  }
  .imageCard {
    padding: 0px !important;
  }

  .subtitleCard {
    padding: 4px 0px 0px 0px !important;
  }

  .imageCard img {
    border-radius: 16px 0px 0px 0px;
  }

  .delButton {
    position: absolute;
    margin-left: 8px;
    margin-top: 0px;
    opacity: 0;
  }

</style>
