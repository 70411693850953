<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <v-textarea
        label="Введите заголовок"
        v-model="data.name"
        single-line
        rows="1"
        auto-grow
        loading="false"
        class="item_header ml-4 mr-4"
        @blur="saveTitle"
      ></v-textarea>

    </v-col>

  </v-row>
</template>

<script>
    export default {
        name: 'HeaderArea',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            }
        }
    }
</script>

<style scoped>
  ::deep (textarea) {
    text-align: center !important;
    line-height: 1.3 !important;
    font-weight: 500;
  }
</style>
