<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">
      <div class="ml-4 mr-4 mb-4" v-html="data.name"></div>
    </v-col>
  </v-row>
</template>

<script>
    export default {
        name: 'DescriptionArea',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item
            }
        }
    }
</script>

<style scoped>

</style>
