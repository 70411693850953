import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Form from '../views/Form.vue'
import List from '../views/List.vue'
import Result from '../views/Result'
import ResultSlug from '../views/ResultSlug'
import ResultBig from '../views/ResultBig'
import ResultSlugBig from '../views/ResultSlugBig'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/f/:slug',
    name: 'Form',
    component: Form
  },
  {
    path: '/f/:slug/:id',
    name: 'FormRef',
    component: Form
  },
  {
    path: '/result/:slug',
    name: 'ResultSlug',
    component: ResultSlug
  },
  {
    path: '/result/big/:id',
    name: 'ResultBig',
    component: ResultBig
  },
  {
    path: '/result/big/slug/:slug',
    name: 'ResultSlugBig',
    component: ResultSlugBig
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
