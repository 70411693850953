export default {
  data () {
    return {
      check_fill: this.item.conf.fill || false
    }
  },
  methods: {
    changeFill: function() {
      this.check_fill = !this.check_fill
      this.item.conf.fill = this.check_fill
      this.$store.dispatch('refreshAnketItem', this.item)
    }
  }
}
