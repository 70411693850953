<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <h1 class="item_title ma-3 ml-0">{{data.name}}</h1>

      <v-list class="pt-0 mt-0">
        <v-radio-group v-model="check">
          <v-list-item v-for="(sel, index) in selItems" :key="'sel'+index" class="selitem">
            <v-radio
              :value="sel"
              color="info"
              class="pt-0 mt-0 mr-1"
              @change="saveData(sel)"
            >
              <template v-slot:label>
                <div class="selitem_title">{{sel}}</div>
              </template>
            </v-radio>

          </v-list-item>
        </v-radio-group>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>

    export default {
        name: 'RadioList',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.check = []
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                selItems: (this.item.variants) ? this.item.variants : [],
                check: null
            }
        },
        methods: {
            saveData: function (v) {
                this.$store.dispatch('saveSignItem', { id: this.item.id, value: v, type: 'radiolist' })
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            }
        },
        mounted() {
            this.check = (this.val) ? this.val : []
        }
    }
</script>

<style scoped>
  .selitem:hover {
    background-color: #eee;
  }

  .selitem_title {
    font-size: 20px;
    font-weight: 400;
    color: #444;
    margin-left: 4px;
  }

  .link {
    font-size: 16px;
  }

  .link:hover {
    color: #295bb5;
  }

  .moveSelItem{
    cursor: move;
  }

</style>
