<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <div class="text-center">
        <iframe class="video-frame" :src="data.name" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

    </v-col>

  </v-row>
</template>

<script>
    export default {
        name: 'TextField',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item
            }
        }
    }
</script>

<style scoped>
  .video-frame {
    width: 100%;
    height: 315px;
    max-width: 560px;
    max-height: 315px;
  }
</style>
