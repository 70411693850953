<template>
  <v-app>
    <Header v-if="($route.name !== 'Form')&&($route.name !== 'ResultSlug')" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
&nbsp;
  </v-app>
</template>

<script>

  import Header from './components/Header'
    export default {
        name: 'App',
        metaInfo: {
            title: 'КоДО.Формы'
        },
        components: {
            Header
        }
    }
</script>

<style>
  .fade-enter-active {
    transition: opacity .1s;
  }
  .fade-leave-active {
    transition: opacity .1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
