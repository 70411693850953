<template>
  <v-row class="mt-3 mb-0 pb-0" v-if="anketa">
    <v-col v-if="loading" class="text-center pt-5">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-col>
    <v-col v-if="!loading">

      <v-tooltip right>
        <template v-slot:activator="{ on}">
          <v-btn
            class="listButton mt-0 ml-6 v-btn--fixed"
            fab
            large
            v-on="on"
            color="#eee"
            @click="anketList"
          >
            <v-icon dark>
              mdi-cards-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Перейти к списку анкет</span>
      </v-tooltip>

      <v-tooltip right :value="badgeTooltip" v-if="anketa.id > 0">
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            v-on="on"
            class="ml-7 v-btn--fixed"
            style="margin-top: 90px"
            @click="goToResult"
            icon>
            <v-icon dark
                    large
                    class="listButton mt-1"
                    color="#333">
              mdi-account-multiple-check
            </v-icon>
          </v-btn>
        </template>
        <span>Посмотреть результаты анкеты</span>
      </v-tooltip>

      <v-badge
        v-if="anketa.id > 0"
        color="#F48611"
        overlap
        bottom
        @click="goToResult"
        :content="(anketa.signCount)?anketa.signCount:'0'"
        class="anketResult"
        style="position: fixed"
      >
      </v-badge>

      <v-carousel
        class="mb-6"
        v-model="page"
        :show-arrows="false"
        hide-delimiter-background
        hide-delimiters
        height="100%"
      >

        <v-carousel-item
          v-for="(page, i) in anketa.pages"
          :key="i"
        >

          <div style="max-width: 800px" class="ma-auto pr-6 pl-6 pr-md-0 pl-md-0">

            <v-tooltip left v-if="i>0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="leftButton pl-0 pr-0"
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="prevPage"
                >
                  <v-icon>
                    mdi-arrow-left-bold
                  </v-icon>
                </v-btn>
              </template>
              <span>Предыдущая страница</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="addPageButton pl-0 pr-0"
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="addPage"
                >
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Создать новую страницу</span>
            </v-tooltip>

            <v-tooltip right v-if="(i+1) < anketa.pages.length">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="rightButton pl-0 pr-0"
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="nextPage"
                >
                  <v-icon>
                    mdi-arrow-right-bold
                  </v-icon>
                </v-btn>
              </template>
                <span>Следующая страница</span>
            </v-tooltip>

            <v-tooltip right v-if="(i+1) <= anketa.pages.length">
              <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="delButton pl-0 pr-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="delPage">
                    mdi-trash-can-outline
                  </v-icon>
              </template>
              <span>Удалить страницу</span>
            </v-tooltip>

            <confirm ref="conf"></confirm>
            <v-card max-width="800" height="100%" class="mt-6" v-if="page">
              <Page :number="i" :page="page"></Page>
            </v-card>
          </div>
        </v-carousel-item>
      </v-carousel>

    </v-col>
  </v-row>
</template>

<script>
    import Page from '../components/Home/Page'
    import Confirm from '../components/Confirm'

    export default {
        name: 'Home',
        components: {
            Page,
            Confirm
        },
        data() {
            return {
                rightPage: false,
                pages: ['1', '1'],
                page: 0,
                loading: true,
                badgeTooltip: false
            }
        },
        mounted() {
            this.$store.dispatch('getAnkets').then((data) => {
                this.$store.dispatch('getAnketByNum').then(() => {
                if (this.anketa.id >= 0) {
                  this.$store.dispatch('getSigns').then(() => {
                      this.loading = false
                  })
                } else this.loading = false
              })
            })
            this.$store.dispatch('getRegions')
            this.$store.dispatch('getEcc')
        },
        computed: {
            anketa: function () {
                return this.$store.getters.currentAnket
            }
        },
        methods: {
            choose: function () {
                this.rightPage = true
            },
            addPage: function () {
                this.$store.dispatch('addPage')
                this.page++
            },
            nextPage: function () {
                this.page++
                this.$store.commit('SET_ANKET_PAGE', this.page)
            },
            prevPage: function () {
                this.page--
                this.$store.commit('SET_ANKET_PAGE', this.page)
            },
            delPage: function () {
                this.$refs.conf[0].open('Предупреждение', 'Вы уверены, что хотите удалить страницу?', {
                    color: '#c96511',
                    width: 290,
                    zIndex: 200
                }).then(conf => {
                    if (conf) {
                        this.$store.dispatch('delPage', this.page)
                    }
                })
            },
            anketList: function () {
                this.$router.push('List')
            },
            goToResult: function () {
              if (this.anketa.signCount > 5) this.$router.push('/result/big/' + this.anketa.id)
              else this.$router.push({ name: 'Result', params: { id: this.anketa.id } })
            }
        }
    }
</script>

<style>

  .listButton:hover {
    color: #407ad8;
    cursor: pointer;
  }

  .addPageButton {
    position: absolute;
    margin-left: 816px;
    margin-top: 24px;
    min-width: 40px !important;
  }

  .leftButton {
    position: absolute;
    margin-left: -60px;
    margin-top: 24px;
    min-width: 40px !important;
  }

  .rightButton {
    position: absolute;
    margin-left: 816px;
    margin-top: 24px;
    min-width: 40px !important;
  }

  .delButton {
    position: absolute !important;
    margin-left: 816px;
    margin-top: 80px;
    min-width: 40px !important;
  }

  .delButton:hover {
    color: #c96511;
  }

  .anketResult {
    margin-top: 134px !important;
    left: 80px;
    z-index:5;
  }

</style>
