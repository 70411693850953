<template>
    <v-tab-item>
        <v-card flat>
            <v-card-title class="text-center lighten-4 grey">
                Перечень анкет (всего: {{ count }})
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" tile large elevation="2" class="float-right"
                            style="position: absolute; right:8px; top: 6px" @click="downloadData"
                            v-if="filteredList.length">
                            <v-icon>mdi-microsoft-excel</v-icon>
                        </v-btn>
                    </template>
                    <span>загрузить результаты в формате Excel</span>
                </v-tooltip>
            </v-card-title>
        </v-card>
        <v-card-text>
            <v-row justify="center" v-if="this.filteredList.length">
                <v-expansion-panels>
                    <v-expansion-panel v-for="(sign, i) in filteredList" :key="i">
                        <v-expansion-panel-header>Анкета №{{ (currentPage - 1) * anketPerPage + i + 1 }}.
                            {{ sign[0].value }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table :headers="headers" :items="sign" :items-per-page="itemsPerPage"
                                class="elevation-3">
                                <template v-slot:item.value="{ item }">
                                    <div v-if="(item.type !== 'document')">{{ item.value }}</div>
                                    <div v-else>
                                        <a :href="link + '/storage/app/' + item.value" target="_blank">скачать документ</a>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-row v-else>
                <div class="text-center ma-auto mt-3 mb-3">На текущий момент ни одной анкеты не было заполнено</div>
            </v-row>
        </v-card-text>
        <v-pagination v-model="currentPage" class="mt-6" :length="Math.ceil(count / anketPerPage)" @input="changePage()"
            :total-visible="10"></v-pagination>
    </v-tab-item>
</template>
<script>
export default {
    name: 'SignList',
    data() {
        return {
            search: '',
            sortBy: '',
            sortDesc: '',
            page: 1,
            itemsPerPage: 10,
            anketPerPage: 10,
            currentPage: 1,
            headers: [{
                text: 'Вопрос',
                align: 'start_small',
                sortable: true,
                value: 'name',
                width: '30%'
            }, {
                text: 'Ответ',
                align: 'start',
                sortable: true,
                value: 'value',
                width: '70%'
            }
            ]
        }
    },
    computed: {
        anket: function () {
            return this.$store.getters.currentAnket
        },
        signs: function () {
            return this.$store.getters.allResults.list
        },
        count: function () {
            return this.$store.getters.allResults.count
        },
        link: function () {
            return this.$store.getters.link
        },
        filteredList: function () {
            if (this.signs) {
                /* this.signs.forEach(sign => {
                    list[sign] = []
                    this.anket.pages.forEach((page, p) => {
                        list[sign][p] = []
                        page.items.forEach((item, i) => {
                            if (item.answers) {
                                list[sign][p][i] = item.answers.filter(answer => {
                                    return (answer.id === sign)
                                })[0]
                                if (list[sign][p][i]) {
                                    list[sign][p][i].name = item.name
                                    list[sign][p][i].type = item.type
                                } else list[sign][p][i] = { name: item.name, value: '' }
                            }
                        })
                    })
                }) */
                var alist = []
                var c = 0
                this.signs.forEach((sign, i) => {
                    alist[c] = []
                    sign.items.forEach((item, i) => {
                            const val = (item.value) ? item.value.replace(/(\[")|("\])|(")/g, '').replace('", "', ', ') : null
                            alist[c].push({ name: item.name, value: val, type: item.type })
                    })
                    c++
                })
            } else alist = []
            return alist
        }
    },
    methods: {
        downloadData: function () {
            window.open(this.link + '/forms/excel/result/list?id=' + this.anket.id, '_blank')
        },
        changePage: function() {
            this.$store.dispatch('getBigSignList', { id: this.anket.id, type: 'list', page: this.currentPage })
        }
    }
}
</script>

<style scoped>
::v-deep .v-card__title {
    padding-left: 2px;
    padding-right: 2px;
    display: block !important;
    word-break: normal !important;
}

::v-deep .v-card__text {
    padding-left: 2px;
    padding-right: 2px;
}

::v-deep .text-start {
    font-size: 14px !important;
}

::v-deep .text-start_small {
    font-size: 12px !important;
    color: #666;
}
</style>
