var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.anketa)?_c('v-row',{staticClass:"mt-3 mb-0 pb-0"},[(_vm.loading)?_c('v-col',{staticClass:"text-center pt-5"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e(),(!_vm.loading)?_c('v-col',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"listButton mt-0 ml-6 v-btn--fixed",attrs:{"fab":"","large":"","color":"#eee"},on:{"click":_vm.anketList}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cards-outline ")])],1)]}}],null,false,316000568)},[_c('span',[_vm._v("Перейти к списку анкет")])]),(_vm.anketa.id > 0)?_c('v-tooltip',{attrs:{"right":"","value":_vm.badgeTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-7 v-btn--fixed",staticStyle:{"margin-top":"90px"},attrs:{"fab":"","icon":""},on:{"click":_vm.goToResult}},on),[_c('v-icon',{staticClass:"listButton mt-1",attrs:{"dark":"","large":"","color":"#333"}},[_vm._v(" mdi-account-multiple-check ")])],1)]}}],null,false,2013464223)},[_c('span',[_vm._v("Посмотреть результаты анкеты")])]):_vm._e(),(_vm.anketa.id > 0)?_c('v-badge',{staticClass:"anketResult",staticStyle:{"position":"fixed"},attrs:{"color":"#F48611","overlap":"","bottom":"","content":(_vm.anketa.signCount)?_vm.anketa.signCount:'0'},on:{"click":_vm.goToResult}}):_vm._e(),_c('v-carousel',{staticClass:"mb-6",attrs:{"show-arrows":false,"hide-delimiter-background":"","hide-delimiters":"","height":"100%"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},_vm._l((_vm.anketa.pages),function(page,i){return _c('v-carousel-item',{key:i},[_c('div',{staticClass:"ma-auto pr-6 pl-6 pr-md-0 pl-md-0",staticStyle:{"max-width":"800px"}},[(i>0)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"leftButton pl-0 pr-0",attrs:{"color":"white"},on:{"click":_vm.prevPage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-left-bold ")])],1)]}}],null,true)},[_c('span',[_vm._v("Предыдущая страница")])]):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"addPageButton pl-0 pr-0",attrs:{"color":"white"},on:{"click":_vm.addPage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Создать новую страницу")])]),((i+1) < _vm.anketa.pages.length)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rightButton pl-0 pr-0",attrs:{"color":"white"},on:{"click":_vm.nextPage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-arrow-right-bold ")])],1)]}}],null,true)},[_c('span',[_vm._v("Следующая страница")])]):_vm._e(),((i+1) <= _vm.anketa.pages.length)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"delButton pl-0 pr-0",on:{"click":_vm.delPage}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v("Удалить страницу")])]):_vm._e(),_c('confirm',{ref:"conf",refInFor:true}),(page)?_c('v-card',{staticClass:"mt-6",attrs:{"max-width":"800","height":"100%"}},[_c('Page',{attrs:{"number":i,"page":page}})],1):_vm._e()],1)])}),1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }