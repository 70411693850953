<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col class="text-center mt-6 mb-4">
          <img :src="(image) ? (link + '/storage/app/banners/' + image) : images.banner" class="imageHeader">
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <div v-for="(item, i) in list.items" :key="'item'+i" class="border-hover mb-3">
        <EccField :number="i" :item="item" v-if="item.type=='eccfield'" />
        <RegionField :number="i" :item="item" v-if="item.type=='regionfield'" />
        <TextField :number="i" :item="item" v-if="item.type=='textfield'" />
        <NumField :number="i" :item="item" v-if="item.type=='numfield'" />
        <TextArea :number="i" :item="item" v-if="item.type=='textarea'" />
        <SelectField :number="i" :item="item" v-if="item.type=='selectfield'" />
        <CheckList :number="i" :item="item" v-if="item.type=='checklist'" />
        <RadioList :number="i" :item="item" v-if="item.type=='radiolist'" />
        <DatePicker :number="i" :item="item" v-if="item.type=='datepicker'" />
        <HeaderArea :number="i" :item="item" v-if="item.type=='headerarea'" />
        <DescriptionArea :number="i" :item="item" v-if="item.type=='descriptionarea'" />
        <Document :number="i" :item="item" v-if="item.type=='document'" />
        <Video :number="i" :item="item" v-if="item.type=='video'" />
        <Rating :number="i" :item="item" v-if="item.type=='rating'" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
    import TextField from './TextField'
    import NumField from './NumField'
    import TextArea from './TextArea'
    import SelectField from './SelectField'
    import CheckList from './CheckList'
    import RadioList from './RadioList'
    import DatePicker from './DatePicker'
    import HeaderArea from './HeaderArea'
    import RegionField from './RegionField'
    import DescriptionArea from './DescriptionArea'
    import Document from './Document'
    import Video from './Video'
    import Rating from './Rating'
    import EccField from './EccField'

    export default {
        name: 'Page',
        props: ['number', 'page'],
        watch: {
            page: function (item) {
                this.list = item
            }
        },
        components: {
            TextField,
            NumField,
            TextArea,
            SelectField,
            CheckList,
            RadioList,
            DatePicker,
            RegionField,
            HeaderArea,
            EccField,
            DescriptionArea,
            Document,
            Video,
            Rating
        },
        data() {
            return {
                images: {
                    banner: require('./../../assets/img/banner3.png')
                },
                list: this.page
            }
        },
        computed: {
            aItems: function () {
                return this.$store.getters.anketItems
            },
            image: function () {
                return this.$store.getters.currentSignAnket.image
            },
            link: function () {
                return this.$store.getters.link
            }
        }
    }
</script>

<style scoped>

  .border-hover:hover {
    outline: 0px solid rgba(242, 157, 63, 0.5);
  }

  .imageHeader {
    max-width: 700px !important
  }

  @media (max-width: 800px) {
    .imageHeader {
      max-width: 500px !important
    }
  }

  @media (max-width: 600px) {
    .imageHeader {
      max-width: 360px !important
    }
  }

  @media (max-width: 400px) {
    .imageHeader {
      max-width: 300px !important
    }
  }
</style>
