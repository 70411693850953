import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

axios.defaults.withCredentials = true

const link = (process.env.NODE_ENV === 'development') ? 'http://localhost/kodo/kodo.api' : 'https://api.kodo.kkr.ru'

export const authStore = {

  state: {
    userSurname: null,
    userName: null,
    userId: null
  },
  getters: {
    currentUserName: state => state.userName,
    currentUserSurname: state => state.userSurname,
    currentUserId: state => state.userId,
    currentUser: state => { return { id: state.userId, name: state.userName, surname: state.userSurname } }
  },
  mutations: {
    SET_USER_DATA: (state, user) => {
      const u = user.name.split(' ')
      state.userSurname = u[0]
      state.userName = u[1]
      state.userId = user.id
      localStorage.setItem('kodo-store-user', JSON.stringify(user))
    },
    GET_USER_DATA: (state, user) => {
      if (localStorage.getItem('kodo-store-user')) {
        Object.assign(user, JSON.parse(localStorage.getItem('kodo-store-user')))
      }
      if (user.id) {
        const u = user.name.split(' ')
        state.userSurname = u[0]
        state.userName = u[1]
        state.userId = user.id
      }
    },
    DELETE_USER_DATA: (state) => {
      localStorage.removeItem('kodo-store-user')
      state.userSurname = null
      state.userName = null
      state.userId = null
    }
  },
  actions: {
    auth_login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(link + '/sanctum/csrf-cookie').then(response => {
          axios.post(link + '/login', {
            email: data.login,
            password: data.password
          }).then((response, error) => {
            if (response.data.user) commit('SET_USER_DATA', response.data.user)
            resolve(response)
          }).catch(error => reject(error))
        })
      })
    },
    auth_register({ commit }, data) {
      axios.post(link + '/register', {
        name: data.name,
        email: data.login,
        password: data.password,
        device_name: 'kodo'
      }).then(response => {

      })
    },
    auth_user({ commit }, data) {
        commit('GET_USER_DATA', {})
    },
    auth_user_leave({ commit }, data) {
      axios.get(link + '/sanctum/csrf-cookie').then(response => {
        commit('DELETE_USER_DATA')
        axios.post(link + '/logout')
      })
    }
  }
}
