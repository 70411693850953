<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <v-textarea
        label="Введите заголовок"
        v-model="data.name"
        single-line
        rows="1"
        auto-grow
        loading="false"
        class="item_title"
        @blur="saveTitle"
      >
        <template v-slot:append>
          <v-tooltip left color="alert">
            <template v-slot:activator="{ on, attrs }">
              <v-icon :color="(check_fill)?'orange':'lightGray'"
                      class="check_fill"
                      @click="changeFill"
                      v-bind="attrs"
                      v-on="on">mdi-check-underline-circle</v-icon>
            </template>
            <span v-if="check_fill">обязательно для заполнения</span>
            <span v-else>не обязательно для заполнения</span>
          </v-tooltip>
        </template>
      </v-textarea>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="DateFormatted"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Выберите дату"
            prepend-icon="mdi-calendar"
            v-model="DateFormatted"
            readonly
            filled
            single-line
            hint="MM/DD/YYYY формат"
            counter="0"
            :rules="rules"
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="menu = false"
          :first-day-of-week="1"
          locale="ru-ru"
        ></v-date-picker>
      </v-menu>

    </v-col>

  </v-row>
</template>

<script>
    import moment from 'moment'
    import HomeItems from '../Mixins/HomeItems.js'
    export default {
        name: 'DatePicker',
        mixins: [HomeItems],
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                date: null,
                menu: false
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            }
        },
        computed: {
            DateFormatted() {
                return this.date ? moment(this.date).locale('ru').format('Do MMMM YYYY, dddd') : ''
            }
        }
    }
</script>

<style scoped>

</style>
