<template>
  <v-container fluid class="pa-0">
    <v-app-bar>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="mt-2 d-none d-sm-block"><img :src="images.logo"></v-toolbar-title>
      <v-toolbar-title class="mt-2 d-block d-sm-none"><img :src="images.logo_mini"></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="($route.name === 'Home') && (anketSlug)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="#3F86C2"
            class="mr-3"
            v-bind="attrs"
            v-on="on"
            large
            @click="lookAtAnket">
            mdi-eye
          </v-icon>
        </template>
        <span>Посмотреть анкету</span>
      </v-tooltip>

      <v-dialog
        v-model="saveDialog"
        width="500"
        v-if="$route.name === 'Home'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="anketStatus"
            color="success"
            v-bind="attrs"
            v-on="on"
            dark>
            <v-icon color="white" size="24" style="margin-top: -4px" class="mr-2">mdi-check-decagram</v-icon>Поделиться
          </v-btn>
          <v-btn
            v-else
            color="#3F86C2"
            v-bind="attrs"
            v-on="((anketId<=0)||(!user.id))?on:''"
            @click="((anketId)&&(user.id))?saveAnket():''"
            class="pt-1"
            dark>
            Сохранить
          </v-btn>
        </template>
        <v-card v-if="!anketStatus">
          <v-card-title>Сохраняем анкету</v-card-title>

          <v-card-text v-if="!user.id">
            Редактирование и просмотр результатов анкеты будет доступна <strong>только на вашем компьютере в текущем браузере</strong>.<br>
            Если вы хотите пользоваться анкетой на других компьютерах или в другом браузере, прежде чем сохранять анкету -
            <Auth :show="false"></Auth>
            Это не займет много времени.
          </v-card-text>
          <v-card-text v-else>
            Вы авторизованы как {{user.name}} {{user.surname}}. Можете сохранять анкету.
          </v-card-text>
          <v-card-text class="mt-1 mb-0 pb-0" v-if="anketId<=0">
            <v-text-field
              v-model="aName"
              label="Введите название анкеты"
              filled
              hint="название будет видеть только редактор анкеты"
              counter="40"
              :rules="rules"
              hide-details="auto"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text class="text-center mt-4">
          <v-btn
            color="blue"
            class="ma-auto"
            dark
            @click="saveAnket"
          >
            Сохранить
          </v-btn>
          </v-card-text>
        </v-card>

        <v-card v-else>
          <v-card-title>Поделиться ссылкой</v-card-title>
          <v-card-text>
            <v-text-field dense hide-details="true" outlined class='w-100' type="text" :value="'https://form.kkr.ru/f/' + this.anketSlug" />
            <input type="hidden"  ref="linkToCopy" :value="'https://form.kkr.ru/f/' + this.anketSlug" />
          </v-card-text>
          <v-card-actions class="text-center">
            <v-tooltip
              open-on-click
              :open-on-focus="false"
              :open-on-hover="false"
              right
            >
              <template v-slot:activator="{ on }">
                <v-btn color="blue"
                       dark
                       v-on="on"
                       class="ma-auto mb-4"
                       @click="copyLink">
                  Скопировать в буфер обмена
                </v-btn>
              </template>
              <span>Ссылка скопирована в буфер обмена</span>
            </v-tooltip>

          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
        v-if="user.id">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-0 ml-6"
            fab
            small
            v-bind="attrs"
            v-on="on"
            color="secondary"
          >
            <v-icon dark>
              mdi-account
            </v-icon>
          </v-btn>
        </template>
        <v-card>
            <v-list class="ml-5">{{user.surname}} {{user.name}}</v-list>
          <v-list class="ml-5 href"><a  @click="userLeave">Выйти</a></v-list>
        </v-card>
      </v-menu>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      left
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <a class="href" href="https://kodo.kkr.ru" target="_blank"><v-list-item class="mt-3">
            <v-list-item-title>КоДО.Проекты</v-list-item-title>
          </v-list-item>
          </a>

          <a class="href" href="https://form.kkr.ru" target="_blank">
          <v-list-item>
            <v-list-item-title>КоДО.Формы</v-list-item-title>
          </v-list-item>
          </a>

          <v-list-item>
            <v-list-item-title class="work">КоДО.Квест</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="work">КоДО.Экспертиза</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="work">КоДО.Программа</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

  </v-container>
</template>

<script>

    import Auth from '../components/Auth'

    export default {
        name: 'Header',
        watch: {
            group () {
                this.drawer = false
            },
            anketStatus() {
                return this.$store.getters.currentAnketStatus
            }
        },
        components: {
          Auth
        },
        data: () => ({
            drawer: false,
            group: null,
            images: {
                logo: require('./../assets/img/logo.png'),
                logo_mini: require('./../assets/img/logo_32.png')
            },
            saveDialog: false,
            rules: [(name) => name.length > 6 || 'Название должно содержать минимум 6 симоволов'],
            aName: ''
        }),
        computed: {
            anketId: function () {
                return this.$store.getters.currentAnketId
            },
            anketStatus: function () {
                return this.$store.getters.currentAnketStatus
            },
            anketSlug: function () {
                return this.$store.getters.currentAnketSlug
            },
            user: function () {
                return this.$store.getters.currentUser
            }
        },
        methods: {
            saveAnket: function () {
                if (((this.aName.length > 6) || (this.anketId)) && !this.anketStatus) {
                    this.$store.dispatch('saveAnket', this.aName)
                    this.saveDialog = false
                    this.aName = ''
                }
            },
            lookAtAnket: function () {
                window.open('/f/' + this.anketSlug, '_blank')
            },
            userLeave: function () {
                this.$store.dispatch('auth_user_leave')
            },
            copyLink: function() {
                var Url = this.$refs.linkToCopy
                Url.setAttribute('type', 'text')
                Url.select()
                document.execCommand('copy')
                Url.setAttribute('type', 'hidden')
                this.copyMessage = 'Ссылка скопирована в буфер обмена'
            }
        },
        mounted() {
            this.$store.dispatch('auth_user')
        }
    }
</script>

<style scoped>

  .href {
    color: #295bb5 !important;
    text-decoration: none;
  }

  .href:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .work:after {
    content: "в разработке";
    color: #eee;
    background-color: #999; /* Цвет фона */
    font-size: 90%; /* Размер шрифта */
    margin-left: 8px;
    padding: 4px 4px; /* Поля вокруг текста */
    border-radius: 6px;
  }
</style>
